<template>
  <div class="modelclass">
    <!-- haha -->
    <mcmc />
  </div>
</template>

<script>
// @ is an alias to /src
import mcmc from "@/components/TheModelSearch.vue";

export default {
  name: "ModelName",
  components: {
    mcmc,
  },
};
</script>
