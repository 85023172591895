<template>
  <v-container>
    <v-app-bar flat dense class="blue-grey lighten-5">
      <v-toolbar-title class="text-h5">製品写真データ</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div v-show="!loading">
      <v-switch
        v-model="viewModel"
        :label="`モデル名: ${viewModel.toString()}`"
      ></v-switch>
      <base-product-table
        v-show="viewModel"
        :post-pas="model"
        class="mb-2"
      ></base-product-table>
      <base-price-table
        v-show="!viewModel"
        :post-pas="pas"
        class="mb-2"
      ></base-price-table>
    </div>
  </v-container>
</template>
<script>
import axios from "axios";
import Processing from "../mixins/processing";
import csv from "csvtojson";
import BaseProductTable from "./Bases/BaseModelsTable";
import BasePriceTable from "./Bases/BasePriceTable";
// var models = null;
export default {
  mixins: [Processing],
  components: {
    BaseProductTable,
    BasePriceTable,
  },
  name: "HelloWorld",
  data: () => ({
    search: "",
    loading: true,
    pas: [],
    model: [],
    viewModel: true,
  }),
  mounted() {
    document.title = "製品写真 | 他社商計";
    this.getData();
  },
  watch: {
    viewModel(newVal) {
      if (!newVal) {
        this.getData2();
      }
    },
  },
  methods: {
    async getData() {
      if (this.model.length === 0) {
        this.loading = true;
        const data = await axios
          .get("https://cova.sakuraweb.com/data/data.php?file=models.txt")
          .then();
        let mydata = data.data;
        this.model = await csv({ delimiter: "\t" }).fromString(mydata).then();
        this.loading = false;
      }
    },
    async getData2() {
      if (this.pas.length === 0) {
        this.loading = true;
        const data = await axios
          .get("https://cova.sakuraweb.com/data/data.php")
          .then();
        let mydata = data.data;
        this.pas = await csv({ delimiter: "\t" }).fromString(mydata).then();
        this.loading = false;
      }
    },
  },
};
</script>
