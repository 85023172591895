var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.postPas,"search":_vm.search,"multi-sort":"","items-per-page":_vm.itemsperpage},on:{"update:itemsPerPage":function($event){_vm.itemsperpage=$event},"update:items-per-page":function($event){_vm.itemsperpage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("一覧")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{staticClass:"ml-4",attrs:{"append-icon":"mdi-magnify","label":"検索","single-line":"","hide-details":""},model:{value:(_vm.search_async),callback:function ($$v) {_vm.search_async=$$v},expression:"search_async"}})],1)]},proxy:true},{key:"item.modelname",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.copyData(item.modelname)}}},[_vm._v(_vm._s(item.modelname))])]}},{key:"item.model",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.copyData(item.model)}}},[_vm._v(_vm._s(item.model))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.copyData(item.category)}}},[_vm._v(_vm._s(item.category))])]}},{key:"item.maker",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.copyData(item.maker)}}},[_vm._v(_vm._s(item.maker))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }