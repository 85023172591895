<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    :items="postPas"
    :search="search"
    multi-sort
    :custom-filter="filterOnlyCapsText"
    :items-per-page.sync="itemsperpage"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>一覧</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn
          text
          @click="clearCondition"
          v-show="isCondition"
          class="red--text"
        >
          検索条件クリア
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search_async"
          append-icon="mdi-magnify"
          label="検索"
          single-line
          hide-details
          class="ml-4"
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:[`item.price`]="{ item }" class="justify-center">
      <span @click="copyData(item.price)">{{ item.price }}</span>
    </template>
    <template v-slot:[`body.append`]>
      <tr>
        <td>
          <v-select
            v-model="modelyear"
            :items="modelyears"
            multiple
            clearable
          ></v-select>
        </td>
        <td>
          <v-select v-model="category" :items="categorise" clearable></v-select>
        </td>
        <td>
          <v-select v-model="maker" :items="makers" clearable></v-select>
        </td>
        <td colspan="4"></td>
        <td>
          <v-select
            v-model="destination"
            :items="destinations"
            clearable
          ></v-select>
        </td>
        <td colspan="6"></td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "ProductTable",
  data: () => ({
    myload: true,
    search: "",
    search_async: "",
    search_temp: "",
    loading: false,
    modelyear: null,
    itemsperpage: 10,
    modelyears: () => {
      const now = dayjs();
      const min = 2006;
      const years = [];
      for (let i = min; i <= now.year() + 2; i++) {
        years.push(i.toString());
      }
      return years;
    },
    category: null,
    categorise: [
      "Sport",
      "Scooter",
      "Cruiser",
      "Off/Compe",
      "Dual purpose",
      "Moped",
      "A-2UP Utility",
      "A-Sports",
      "A-Utility",
      "A-Youth",
      "R-Multipurpose",
      "R-Recreation",
      "R-Sports",
      "R-Utility",
      "R-Youth",
    ],
    maker: null,
    makers: [
      "HONDA",
      "KAWASAKI",
      "SUZUKI",
      "ARCTIC CAT",
      "BAJAJ",
      "BMW",
      "BOMBARDIER",
      "DUCATI",
      "HARLEY DAVIDSON",
      "HERO",
      "JOHN DEERE",
      "KTM",
      "KYMCO",
      "MODENAS",
      "PIAGGIO",
      "POLARIS",
      "SYM",
      "TEXTRON",
      "TRIUMPH",
      "VESPA",
      "VICTORY",
      "YAMAHA",
    ],
    destination: null,
    destinations: [
      "USA",
      "ITA",
      "JPN",
      "CHN",
      "IND",
      "BRA",
      "COL",
      "DEU",
      "FRA",
      "IDN",
      "MYS",
      "PHL",
      "THA",
      "TWN",
      "VNM",
    ],
  }),
  props: {
    postPas: {
      type: Array,
    },
    postMydb: {
      type: Function,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "モデルイヤー",
          value: "modelyear",
          filter: (value) => {
            if (!this.modelyear) return true;
            if (this.modelyear.length === 0) return true;
            return this.modelyear.indexOf(value) > -1;
          },
        },
        {
          text: "カテゴリ",
          value: "category",
          filter: (value) => {
            if (!this.category) return true;
            return value === this.category;
          },
        },
        {
          text: "メーカー",
          value: "maker",
          filter: (value) => {
            if (!this.maker) return true;
            return value === this.maker;
          },
        },
        { text: "モデル名", value: "modelname" },
        { text: "開発区分", value: "class", filterable: false },
        { text: "開発ランク", value: "rank", filterable: false },
        { text: "排気量", value: "cc" },
        {
          text: "仕向地",
          value: "destination",
          filter: (value) => {
            if (!this.destination) return true;
            return value === this.destination;
          },
        },
        {
          text: "立上年月（発売時期）",
          value: "releasedate",
          filterable: false,
        },
        { text: "Ｅ／Ｇ", value: "eg" },
        { text: "Ｂ／Ｄ", value: "bd" },
        { text: "Memo", value: "memo" },
        { text: "通貨単位", value: "c_type", filterable: false },
        { text: "価格", value: "price", filterable: false },
      ];
    },
    isCondition() {
      if (
        this.search ||
        this.modelyear ||
        this.category ||
        this.maker ||
        this.destination
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    postMydb: function () {
      if (!this.myload) {
        return;
      }
      window.setTimeout(() => {
        const con = JSON.parse(window.localStorage.getItem("baseproductcon"));
        this.myload = false;
        if (!con) {
          return;
        }
        this.search = con.search;
        this.search_async = con.search;
        this.modelyear = con.modelyear;
        this.category = con.category;
        this.maker = con.maker;
        this.destination = con.destination;
      }, 500);
    },
    search_async: function (val) {
      this.search_temp = val;
      window.setTimeout(() => {
        if (this.search_temp === this.search_async) {
          this.search = this.search_async;
        }
      }, 500);
    },
  },
  methods: {
    clearCondition() {
      this.search = null;
      this.search_async = null;
      this.modelyear = null;
      this.category = null;
      this.maker = null;
      this.destination = null;
      return false;
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    getFiltered() {
      var con = {};
      if (this.myload) {
        return;
      }
      con.search = this.search;
      con.modelyear = this.modelyear;
      con.category = this.category;
      con.maker = this.maker;
      con.destination = this.destination;
      window.localStorage.setItem("baseproductcon", JSON.stringify(con));
    },
    async copyData(copy) {
      // 空div 生成
      var tmp = document.createElement("div");
      // 選択用のタグ生成
      var pre = document.createElement("pre");

      // 親要素のCSSで user-select: none だとコピーできないので書き換える
      pre.style.webkitUserSelect = "auto";
      pre.style.userSelect = "auto";

      tmp.appendChild(pre).textContent = copy;

      // 要素を画面外へ
      var s = tmp.style;
      s.position = "fixed";
      s.right = "200%";

      // body に追加
      document.body.appendChild(tmp);
      // 要素を選択
      document.getSelection().selectAllChildren(tmp);

      // クリップボードにコピー
      document.execCommand("copy");

      // 要素削除
      document.body.removeChild(tmp);
    },
  },
};
</script>
