<template>
  <v-data-table
    :headers="headers"
    :items="postPas"
    :search="search"
    multi-sort
    :items-per-page.sync="itemsperpage"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>一覧</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="search_async"
          append-icon="mdi-magnify"
          label="検索"
          single-line
          hide-details
          class="ml-4"
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:[`item.modelname`]="{ item }" class="justify-center">
      <span @click="copyData(item.modelname)">{{ item.modelname }}</span>
    </template>
    <template v-slot:[`item.model`]="{ item }" class="justify-center">
      <span @click="copyData(item.model)">{{ item.model }}</span>
    </template>
    <template v-slot:[`item.category`]="{ item }" class="justify-center">
      <span @click="copyData(item.category)">{{ item.category }}</span>
    </template>
    <template v-slot:[`item.maker`]="{ item }" class="justify-center">
      <span @click="copyData(item.maker)">{{ item.maker }}</span>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: "ModelTable",
  data: () => ({
    myload: true,
    search: "",
    search_async: "",
    search_temp: "",
    loading: false,
    modelyear: null,
    itemsperpage: 10,
    src: {},
  }),
  props: {
    postPas: {
      type: Array,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "モデル名",
          value: "modelname",
        },
        {
          text: "モデル群",
          value: "model",
        },
        {
          text: "カテゴリ",
          value: "category",
          filterable: false,
        },
        {
          text: "メーカー",
          value: "maker",
          filterable: false,
        },
      ];
    },
  },
  watch: {
    search_async: function (val) {
      this.search_temp = val;
      window.setTimeout(() => {
        if (this.search_temp === this.search_async) {
          this.search = this.search_async;
        }
      }, 500);
    },
  },
  methods: {
    async copyData(copy) {
      // 空div 生成
      var tmp = document.createElement("div");
      // 選択用のタグ生成
      var pre = document.createElement("pre");

      // 親要素のCSSで user-select: none だとコピーできないので書き換える
      pre.style.webkitUserSelect = "auto";
      pre.style.userSelect = "auto";

      tmp.appendChild(pre).textContent = copy;

      // 要素を画面外へ
      var s = tmp.style;
      s.position = "fixed";
      s.right = "200%";

      // body に追加
      document.body.appendChild(tmp);
      // 要素を選択
      document.getSelection().selectAllChildren(tmp);

      // クリップボードにコピー
      document.execCommand("copy");

      // 要素削除
      document.body.removeChild(tmp);
    },
  },
};
</script>
